




























































































import { LoadingStatusType } from "@/enums/enums";
import {
  ContactTemplateModel,
  GetContactTemplateByTaxIdRequest,
  UpdateLogoUrlContactTemplateRequest,
} from "@/model/contact/contactModel";
import { ValidateUploadLogoContact } from "@/helper/uploadHelper";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreContact = namespace("Contact");

@Component
export default class UpdateLogoUrlContactTemplate extends Vue {
  private textSearchTaxId = "";
  private file = {};
  private renderImg = true;
  private contactLogoList: Array<any> = [];
  private isUpdateContactLogo = false;
  private isEdit = false;

  @StoreContact.Getter
  private getContactTemplateByTaxIdLoadingStatus!: LoadingStatusType;
  @StoreContact.Getter
  private getContactTemplateByTaxId!: ContactTemplateModel;
  @StoreContact.Getter
  private getUpdateLogoUrlContactLoadingStatus!: LoadingStatusType;

  @StoreContact.Action
  private DoGetContactTemplateByTaxId!: (input: GetContactTemplateByTaxIdRequest) => void;
  @StoreContact.Action
  private DoUpdateLogoUrlContactTemplate!: (input: UpdateLogoUrlContactTemplateRequest) => void;

  @StoreContact.Mutation
  private ReSetContactTemplateByTaxId!: () => void;

  get getContactTemplateByTaxIdSuccess() {
    return this.getContactTemplateByTaxIdLoadingStatus === LoadingStatusType.Success && this.getContactTemplateByTaxId;
  }

  @Watch("getContactTemplateByTaxIdLoadingStatus", { immediate: true })
  getContactTemplateByTaxIdStatusChanged(newvalue: LoadingStatusType) {
    if (newvalue === LoadingStatusType.Success && this.getContactTemplateByTaxId) {
      this.SetLogoDefault();
    }
  }

  @Watch("getUpdateLogoUrlContactLoadingStatus")
  getUpdateLogoUrlContactStatusChanged(newvalue: LoadingStatusType) {
    if (newvalue === LoadingStatusType.Success) {
      this.SearchContactTemplateByTaxId();
    }
  }

  public SearchContactTemplateByTaxId() {
    if (this.textSearchTaxId.trim().length > 0) {
      const input = {
        taxId: this.textSearchTaxId.trim(),
      };
      this.DoGetContactTemplateByTaxId(input);
    }
  }

  public SetLogoDefault() {
    this.renderImg = false;
    this.contactLogoList = [];
    this.$nextTick(() => {
      this.contactLogoList.push({
        croppa: {},
        fileName: "",
        fileType: "",
        rawString: "",
        urlImg: this.getContactTemplateByTaxId.logoUrl,
      });
      this.renderImg = true;
    });
  }

  public ChooseProfile(index: number) {
    if (this.isEdit) {
      this.contactLogoList[index].croppa.chooseFile();
    }
  }

  public ImageChanged(index: number) {
    const croppa = this.contactLogoList[index].croppa;
    const file = croppa.getChosenFile();
    const dataUrl = croppa.originalImage.src;

    let name = "";
    let type = "";
    let rawString = "";
    let size = 0;

    if (file) {
      name = file.name;
      type = file.type;
      rawString = dataUrl;
      size = file.size;
      this.isUpdateContactLogo = true;
    } else {
      const originalImage = croppa.originalImage.src;
      name = originalImage.substring(originalImage.lastIndexOf("/") + 1);
      type = originalImage.split(/[#?]/)[0].split(".").pop().trim();
      rawString = dataUrl;
    }
    this.contactLogoList[index].fileName = name;
    this.contactLogoList[index].fileType = type;
    this.contactLogoList[index].rawString = rawString;
    this.contactLogoList[index].size = size;
  }

  public RemoveImage(index: number) {
    this.contactLogoList[index].croppa.remove();
    this.contactLogoList[index].fileName = "";
    this.contactLogoList[index].fileType = "";
    this.contactLogoList[index].rawString = "";

    if (this.getContactTemplateByTaxId.logoUrl) {
      this.isUpdateContactLogo = true;
    }
  }

  public Submit() {
    if (ValidateUploadLogoContact(this.contactLogoList[0])) {
      const input = {
        taxId: this.getContactTemplateByTaxId.taxId,
        fileModel: {
          rawString: this.contactLogoList[0].rawString,
          fileUrl: this.contactLogoList[0].urlImg,
          fileType: this.contactLogoList[0].fileType,
        },
      };
      this.DoUpdateLogoUrlContactTemplate(input);
      this.isEdit = false;
    }
  }

  public Cancel() {
    this.SetLogoDefault();
    this.isEdit = false;
    this.isUpdateContactLogo = false;
  }

  destroyed() {
    this.ReSetContactTemplateByTaxId();
  }
}
