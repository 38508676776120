export function ValidateUploadLogoContact(file: any) {
  const fileName = file.fileName;
  const fileImageTypeList = [".png", ".jpg", ".jpeg"];
  const maxSize = 3;

  if (fileName.length > 0) {
    let validate = false;

    for (let j = 0; j < fileImageTypeList.length; j++) {
      const fileType = fileImageTypeList[j];
      if (fileName.substr(fileName.length - fileType.length, fileType.length).toLowerCase() == fileType.toLowerCase()) {
        validate = true;
        break;
      }
    }
    if (!validate) {
      alert(`ไม่สามารถนำเข้าได้ สกุลไฟล์ที่อนุญาตมีดังนี้ ${fileImageTypeList}`);
      return false;
    }
  }

  if (file.size / 1024 / 1024 > maxSize) {
    alert(`ไม่สามารถอัปโหลดไฟล์ขนาดเกิน ${maxSize} MB.`);
    return false;
  }
  return true;
}
